.navbar {
    background-color: #3f422e;
    padding: 15px 30px;
    height: auto; /* Changed to auto to avoid height restrictions */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allow wrapping of elements on smaller screens */
}

.navbar-brand {
    color: #f5e6c5;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap; /* Prevent the brand text from breaking */
}

.navbar-brand:hover {
    color: #d78b30;
}

.navbar-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    flex-wrap: wrap; /* Allow navigation links to wrap */
}

.navbar-nav .nav-link {
    color: #f5e6c5;
    font-size: 16px;
    margin-left: 20px;
    transition: color 0.3s ease;
    white-space: nowrap; /* Prevent links from breaking */
}

.navbar-nav .nav-link:hover {
    color: #d78b30;
}

.language-dropdown .dropdown-toggle {
    background-color: #3f422e;
    border: none;
    color: #f5e6c5;
    font-size: 16px;
    font-weight: normal;
    box-shadow: none;
    margin-left: 20px;
}

.language-dropdown .dropdown-toggle:hover,
.language-dropdown .dropdown-toggle:focus {
    background-color: #3f422e;
    color: #d78b30;
}

/* Responsive design adjustments */
@media (max-width: 1024px) {
    .navbar {
        flex-direction: column; /* Stack items vertically on small screens */
        padding: 10px 20px;
    }

    .navbar-brand {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .navbar-nav {
        flex-direction: row; /* Keep navigation links horizontal */
        flex-wrap: wrap; /* Allow wrapping if there are too many links */
        justify-content: center; /* Center the navigation links */
    }

    .navbar-nav .nav-link {
        margin: 10px 10px; /* Add more spacing to links */
    }

    .language-dropdown .dropdown-toggle {
        margin: 10px 0;
    }
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
        padding: 10px 20px;
    }

    .navbar-brand {
        font-size: 18px;
    }

    .navbar-nav {
        flex-direction: column;
        margin-top: 10px;
    }

    .navbar-nav .nav-link {
        margin: 10px 0;
    }

    .language-dropdown .dropdown-toggle {
        margin-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar {
        padding: 15px 50px;
    }

    .navbar-brand {
        font-size: 28px;
    }

    .navbar-nav .nav-link {
        font-size: 18px;
    }
}
