/* General Layout */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Georgia', serif;
  background-color: #FAF5F0;
}

.aboutme-photo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  margin: auto;
  max-width: 1200px;
}

/* Image Section */
.image-section {
  flex: 1;
  margin-right: 60px; 
}

.aboutme-image {
  width: 100%;
  max-width: 400px; 
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Content Section */
.content-section {
  flex: 2;
  color: #5A3E36;
}

.aboutme-title {
  color: #D58A62;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 20px;
}

.aboutme-secondary-title {
  color: #D58A62;
  font-size: 24px;
  margin-bottom: 15px;
}

.content-section p {
  font-size: 18px; 
  line-height: 1.6;
  margin-bottom: 25px;
  color: #6C4B40;
}

/* Contact Section */
.contact-section {
  flex: 1;
  background-color: #FFF7F0;
  border: 1px solid #F1DED2;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-section h4 {
  color: #D58A62;
  margin-bottom: 20px;
  font-size: 24px;
}

.contact-section ul {
  list-style-type: none;
  padding: 0;
}

.contact-section ul li {
  margin-bottom: 18px;
}

.contact-section ul li a {
  color: #B34B58;
  text-decoration: none;
  font-weight: bold;
}

.contact-section ul li a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .aboutme-photo-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
  }
  
  .image-section, .content-section, .contact-section {
      width: 100%;
      margin: 0;
      margin-bottom: 30px;
  }
  
  .aboutme-title {
      text-align: center;
      margin-bottom: 30px;
  }
}
