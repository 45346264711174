/* font palette
    #f5e6c5 orange, details
    #3f422e green, writing
    #d78b30 strong orange, background
    #9F886F
*/

/* General Styling */
html,body {
    font-family: 'Poppins', sans-serif;
    background-color: #f5e6c5;
    color: #333;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

.app-container {
    margin: 0 auto;
    min-height: 100vh;
    background-color: inherit; 
}

h1, h2 {
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 30px;
    text-align: center;
}

/* Forms */
.form-control {
    border: 2px solid #bdc3c7;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    margin-bottom: 20px;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #3498db;
    outline: none;
}

.btn {
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    background-color: #3498db;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #2980b9;
}

/* Admin Dashboard */
.admin-dashboard {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
}

.admin-dashboard h2 {
    margin-bottom: 20px;
    color: #2c3e50;
    font-size: 24px;
    font-weight: 600;
}

.admin-dashboard .form-group label {
    font-weight: 500;
    margin-bottom: 8px;
    display: block;
    color: #34495e;
}

/* Buttons */
.btn-block {
    display: block;
    width: 100%;
    font-size: 16px;
}

.btn-primary {
    background-color: #e74c3c;
    border-color: #e74c3c;
}

.btn-primary:hover {
    background-color: #c0392b;
    border-color: #c0392b;
}

.btn-success {
    background-color: #2ecc71;
    border-color: #27ae60;
}

.btn-success:hover {
    background-color: #27ae60;
}

/* Footer */
footer {
    background-color: #2c3e50;
    padding: 20px 0;
    color: #fff;
    text-align: center;
    margin-top: 40px;
}

footer p {
    margin: 0;
}

.dropdown-item {
    cursor: pointer;
    color: #333;
  }
  
  .dropdown-item:hover {
    background-color: #f8f9fa;
  }
  

  