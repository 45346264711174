.masonry-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .masonry-item {
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
  }
  
  /* Portrait images */
  .masonry-item.portrait {
    grid-row: span 2;
  }
  
  /* Landscape images */
  .masonry-item.wide {
    grid-column: span 2;
  }
  
  /* Image styling */
  .masonry-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .masonry-item img:hover {
    transform: scale(1.05);
  }
    
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    max-width: 80vw;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .modal-nav-left, .modal-nav-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    border: none;
    font-size: 2rem;
  }
  
  .modal-nav-left {
    left: -2rem;
  }
  
  .modal-nav-right {
    right: -2rem;
  }
  
  .modal-content img {
    cursor: default;
  }