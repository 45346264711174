/* Preserve the original design for desktop */
.container-category {
  display: flex;
  height: 90vh; 
  justify-content: flex-end;
  min-width: 100%;
  overflow-y: hidden;
}

/* Card hover effect */
.card {
  position: relative;
  width: 30%;
  height: 100%; 
  background-size: cover;
  background-position: center;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05); 
}

.overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
}

.text-container {
  text-align: center;
}

.category-text {
  font-size: 18px;
  font-weight: bold;
}

/* Mobile and Tablet Adjustments */
@media (max-width: 1024px) {
  .container-category {
      flex-direction: column;
      height: auto;
      justify-content: center;
  }

  .card {
      width: 100%;  /* Full width for each card on mobile and tablet */
      height: 50vh;  /* Adjusted height for better fit on smaller screens */
      margin-bottom: 20px;  /* Add some spacing between cards */
  }

  .overlay {
      bottom: 5px;
      left: 5px;
      padding: 8px;  /* Slightly smaller padding for mobile */
  }

  .category-text {
      font-size: 16px;  /* Slightly smaller text for mobile readability */
  }
}

@media (max-width: 768px) {
  .card {
      height: 40vh;  /* Smaller height for mobile screens */
  }

  .category-text {
      font-size: 14px;  /* Further reduction in text size for mobile */
  }
}
